import React from 'react'
import ProfilePanel from './ProfilePanel'
import useAuth from '../../hooks/useAuth'
import axios from '../../api/axios'

const PanelContainer = ({ user, setuser }) => {
  const { getAccessToken } = useAuth()
  const token = getAccessToken()
  const EDIT_USER = '/user/profile'
  const editUser = async (data) => {
    try {
      await axios.put(EDIT_USER, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (e) {
      console.log('error', e)
    }
  }
  return (
    <div className="space-y-5 mt-7 pt-0.5">
      <ProfilePanel
        name="Name"
        content={user.firstname}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="About you"
        content={user.about}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="Age"
        content={user?.dob}
        setuser={setuser}
        callApi={editUser}
      />
      <ProfilePanel
        name="Category"
        content={user.categories}
        setuser={setuser}
      />
      <ProfilePanel
        name="Legal"
        content="Imprint, Privacy, Licences, Data & Account"
      />
    </div>
  )
}

export default PanelContainer
