import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import useAuth from "../hooks/useAuth";
import useMessageContext from "../hooks/useMessageContext";
import H1 from "../components/profile/H1";
import Layout from "../templates/Layout";
import Button from "../shared/Button";
import Stats from "../assets/SVG/stats.svg";
import logoutImg from "../assets/SVG/logout.svg";
import addMedia from "../assets/SVG/addMedia.svg";
import PanelContainer from "../components/profile/PanelContainer";
import CurrentProfileModal from "../components/profile/CurrentProfileModal";
import {
  DisplayPicture,
  ChangePicDialog,
} from "../components/profile/DisplayPicture";
import axios from "../api/axios";
import { Store } from "../stores/Stores";
import DeleteImage from "../components/profile/DeleteImage";
import socket from "../socket/socket";
import ModalImageNudes from "../components/profile/ModalImageNudes";

const Profile = () => {
  const { setChats2, setChats } = Store().chatStore;
  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingFileUploaded, setLoadingFileUploaded] = useState(false);
  const [user, setuser] = useState({
    firstname: "",
    about: "",
    categories: [],
    avatar: "",
    images: [],
    isUpdated: false,
    role: "",
  });
  console.log(user);
  // const [scrollPosition, setScrollPosition] = useState(0)
  // const handleScroll = (event) => {
  //   setScrollPosition(event.target.scrollLeft)
  // }
  const [dpModal, setdpModal] = useState(false);
  const [showImageNudes, setShowImageNudes] = useState(false);
  const [dataNudes, setDataNudes] = useState({
    title: "",
    imageLink: "",
    label: [],
  });
  const [settingsModal, setsettingsModal] = useState(false);
  const [cloudModal, setCloudModal] = useState(false);
  const [modal, setmodal] = useState("");
  const [filteredPreferences, setFilteredPreferences] = useState([]);

  const { t } = useTranslation();
  const { logout, getAccessToken } = useAuth();
  const token = getAccessToken();
  const { preferences } = Store().preferencesStore;
  const allPreferences = toJS(preferences);
  const USER_PROFILE_URL = "/user/profile";

  const [selectedImage, setSelectedImage] = useState("");
  const handleDeleteImage = (key) => {
    setSelectedImage(key);
    setDeleteModal(true);
  };
  const { initialState } = useMessageContext();
  const { setProfileData } = Store().profileStore;
  const [userList, setuserList] = initialState || [];
  console.log("dataaa", dataNudes);
  const handleLogout = async () => {
    if (userList?.length) setuserList([]);
    setChats([]);
    setChats2([]);
    setProfileData({});
    socket.disconnect();
    logout();
  };
  const ADD_IMAGE_URL = "/user/images";
  const fetchUserProfile = async () => {
    try {
      console.log("calling Api -> costly");
      const response = await axios.get(USER_PROFILE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      if (response?.data?.data) {
        console.log("hahahahahahahaha", response.data);
        const firstname = response?.data.data?.firstname
          ? response?.data.data?.firstname
          : "";
        const about = response?.data.data?.about;
        const categories = response?.data.data?.categories;
        const avatar = response?.data.data?.avatar;
        const images = response?.data.data?.images;
        const role = response?.data.data?.role;

        setuser({
          firstname,
          about,
          categories,
          avatar,
          images,
          role,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const closeModalImageNudes = () => {
    setShowImageNudes(!showImageNudes);
  };
  const postImage = async (formData) => {
    try {
      const res = await axios.post(ADD_IMAGE_URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.result === 3) {
        const objNudes = {
          title: res.data.error,
          imageLink: res.data.image.base64,
          label: res.data.labels,
        };
        setDataNudes(objNudes);
        closeModalImageNudes();
      }
      console.log(res);
      fetchUserProfile();
    } catch (e) {
      console.log("error", e);
    }
  };
  // const firstVisibleIndex = Math.floor(scrollPosition / (75 + 2));

  const filterPreferences = () => {
    try {
      const filteredPrefs = allPreferences
        .filter((preference) =>
          user.categories.some((item) => item === preference.preference._id),
        )
        .map((preference) => preference.preference);
      if (filteredPrefs.length > 0) {
        setFilteredPreferences(filteredPrefs);
        setuser((prev) => ({
          ...prev,
          categories: filteredPrefs,
          isUpdated: true,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log(filteredPreferences);
  useEffect(() => {
    filterPreferences();
  }, [user.categories]);

  const updateUserDetails = async () => {
    setuser((prev) => ({ ...prev, isUpdated: false }));
    // const userData = {
    //   name: user?.name,
    //   about: user?.about,
    //   preferences: user?.categories,
    // }
    // try {
    //   console.log('posting to Api -> costly')
    //   const response = await axios.put(USER_PROFILE_URL, userData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   console.log(response)
    //   if (response?.data?.data?.Message)
    //     console.log(response?.data?.data?.Message)
    // } catch (e) {
    //   console.log('error ', e)
    // }
  };
  const [imageFile, setImageFile] = useState(null);
  const fileInput = useRef(null);
  const handleClick = () => {
    fileInput.current.click();
  };
  useEffect(() => {
    if (imageFile !== null) {
      const formData = new FormData();
      formData.append("file", imageFile);
      postImage(formData);
    }
  }, [imageFile]);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    console.log("rerenders");
    if (user.isUpdated) updateUserDetails();
  }, [user.isUpdated]);

  const opensettingsModal = (e) => {
    console.log("toggling stats");
    setsettingsModal(!settingsModal);
    setmodal(e.target.id);
  };
  const openClooudModal = () => {
    setCloudModal(!cloudModal);
    const namee = "cloud";
    setmodal(namee);
  };
  const openCPModal = (e) => {
    console.log(e.target.id);
    setsettingsModal(!settingsModal);
    console.log("toggling cp");
    setmodal(e.target.id);
  };
  console.log(modal);
  const changeDp = async () => {
    setdpModal(!dpModal);
  };

  const changeDeleteModal = async () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <Layout>
      <header>
        <H1>Profile</H1>
      </header>
      <section className="px-5 h-auto pt-5 absolute top-73px pb-14 bottom-73px inset-0 overflow-y-auto">
        <DisplayPicture
          imgSrc={user.avatar.url}
          handleClick={changeDp}
          loadingFileUploaded={loadingFileUploaded}
          setLoadingFileUploaded={setLoadingFileUploaded}
        />
        <div
          style={{
            display: "flex",
            maxHeight: 100,
            marginTop: 30,
            overflow: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
            WebkitScrollbar: { width: 0, height: 0, display: "none" },
          }}
        >
          <div
            role="group"
            style={{
              // backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 2,
              marginLeft: 10,
              minHeight: 100,
              border: "0.5px dashed black",
              minWidth: 80,
            }}
            onClick={handleClick}
          >
            <input
              type="file"
              accept="image/*"
              ref={fileInput}
              style={{ display: "none" }}
              onChange={(e) => setImageFile(e.target.files[0])}
            />
            <img src={addMedia} alt={addMedia} />
          </div>
          {user.images.map((data) => (
            <img
              key={data.key}
              src={data.url}
              alt={data.key}
              className="bg-whitesmoke min-h-100 min-w-50 object-contain w-[100px]"
              onClick={() => handleDeleteImage(data)}
            />
          ))}
        </div>
        <DeleteImage
          imgSrc={selectedImage}
          isOpen={deleteModal}
          handleClick={changeDeleteModal}
          refresh={fetchUserProfile}
        />

        <ChangePicDialog
          imgSrc={user.avatar}
          isOpen={dpModal}
          handleClick={changeDp}
          refresh={fetchUserProfile}
          setDataNudes={setDataNudes}
          closeModalImageNudes={closeModalImageNudes}
          loadingFileUploaded={loadingFileUploaded}
          setLoadingFileUploaded={setLoadingFileUploaded}
        />
        <ModalImageNudes
          isOpen={showImageNudes}
          closeModal={closeModalImageNudes}
          dataNudes={dataNudes}
        />
        <PanelContainer user={user} setuser={setuser} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            m="mt-10 font-semibold"
            id="calendar"
            onClick={openClooudModal}
          >
            <img className="mr-2.5 inline" src={Stats} alt={Stats} />
            Media Cloud
          </Button>
          <Button
            m="mt-10 font-semibold"
            onClick={opensettingsModal}
            id="Stats"
          >
            <img className="mr-2.5 inline" src={Stats} alt={Stats} />
            {t("View Stats")}
          </Button>
        </div>

        <div className="flex justify-center items-center">
          {user.role === "admin" ? (
            <Button
              m="mt-10 font-semibold"
              onClick={openCPModal}
              id="ControlPanel"
            >
              <img className="mr-2.5 inline" src={Stats} alt={Stats} />
              Control Panel
            </Button>
          ) : null}
        </div>
        <button
          type="submit"
          className="flex p-2.5 border-2 border-black-light mx-auto rounded-xl mt-20"
          onClick={handleLogout}
        >
          Log Out
          <img className="ml-3.5" src={logoutImg} alt={logoutImg} />
        </button>
      </section>
      {modal && (
        <CurrentProfileModal
          modal={modal}
          isOpen={settingsModal}
          setisOpen={setsettingsModal}
        />
      )}
      {cloudModal && (
        <CurrentProfileModal
          modal={modal}
          isOpen={cloudModal}
          setisOpen={setCloudModal}
        />
      )}
    </Layout>
  );
};

export default Profile;
