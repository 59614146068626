import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MobileOnly from "./MobileOnly";
import Desktop from "../pages/Desktop";
import Login from "../pages/Login";
import DeleteAccount from "../pages/DeleteAccount";
import Forgot from "../pages/Forgot";
import Messages from "../pages/Messages";
import Profile from "../pages/Profile";
import Discover from "../pages/Discover";
import Preferences from "../pages/Preferences";
import Reset from "../pages/Reset";
import Chat from "../pages/Chat";
import ChatBroadcast from "../pages/ChatBroadcast";
import Invite from "../pages/Invite";
import ProtectedRoutes from "./ProtectedRoutes";
import OnBoarding from "../pages/OnBoarding";
import Broadcast from "../pages/Broadcast";
import Picks from "../pages/Picks";
import ReadyToMeet from "../pages/ReadyToMeet";
import { Store } from "../stores/Stores";
import UnreadList from "../pages/UnreadList";

const AppRoutes = () => {
  const { getProfile } = Store().profileStore;
  const navigate = useNavigate();
  const location = useLocation();
  const checkPic = async () => {
    try {
      const profile = await getProfile();
      const checkProfilePic = async () => {
        const currentUrl = location.pathname;
        if (profile?.avatarUrl && currentUrl === "/onboarding") {
          console.log(profile?.avatarUrl);
          console.log("messages");
          navigate("/messages");
        }
      };
      checkProfilePic();
    } catch (err) {
      console.dir(err);
    }
  };

  useEffect(() => {
    checkPic();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route
          path="/messages"
          element={
            <MobileOnly>
              <Messages />
            </MobileOnly>
          }
        />
        <Route
          path="/unread"
          element={
            <MobileOnly>
              <UnreadList />
            </MobileOnly>
          }
        />
        <Route
          path="/preferences"
          element={
            <MobileOnly>
              <Preferences />
            </MobileOnly>
          }
        />
        <Route
          path="/discover"
          element={
            <MobileOnly>
              <Discover />
            </MobileOnly>
          }
        />
        <Route
          path="/profile"
          element={
            <MobileOnly>
              <Profile />
            </MobileOnly>
          }
        />
        <Route
          path="/picks"
          element={
            <MobileOnly>
              <Picks />
            </MobileOnly>
          }
        />
        <Route
          path="/ready-to-meet"
          element={
            <MobileOnly>
              <ReadyToMeet />
            </MobileOnly>
          }
        />
        <Route
          path="/chat"
          element={
            <MobileOnly>
              <Chat />
            </MobileOnly>
          }
        />
        <Route
          path="/chatbroadcast"
          element={
            <MobileOnly>
              <ChatBroadcast />
            </MobileOnly>
          }
        />
        <Route
          path="/broadcast"
          element={
            <MobileOnly>
              <Broadcast />
            </MobileOnly>
          }
        />
      </Route>
      <Route
        path="/login"
        element={
          <MobileOnly>
            <Login />
          </MobileOnly>
        }
      />
      <Route
        path="/forgot"
        element={
          <MobileOnly>
            <Forgot />
          </MobileOnly>
        }
      />
      <Route
        path="/signup"
        element={
          <MobileOnly>
            <OnBoarding />
          </MobileOnly>
        }
      />
      <Route
        path="/onboarding"
        element={
          <MobileOnly>
            <OnBoarding />
          </MobileOnly>
        }
      />
      <Route
        path="/reset"
        element={
          <MobileOnly>
            <Reset />
          </MobileOnly>
        }
      />
      <Route
        path="/DeleteAccount"
        element={
          <MobileOnly>
            <DeleteAccount />
          </MobileOnly>
        }
      />

      <Route
        path="/invite/:token"
        element={
          <MobileOnly>
            <Invite />
          </MobileOnly>
        }
      />
      <Route
        path="*"
        element={
          <MobileOnly>
            <Navigate to="/login" />
          </MobileOnly>
        }
      />
      <Route path="/desktop" exact element={<Desktop />} />
    </Routes>
  );
};

export default AppRoutes;
