import React, { useState, useEffect, memo } from 'react'
import Arrow from '../../assets/SVG/ProfileArrow.svg'
import CurrentProfileModal from './CurrentProfileModal'

const ProfilePanel = ({ name = '', content, setuser, callApi }) => {

  const [isOpen, setisOpen] = useState(false);
  const [modal, setmodal] = useState('');

  useEffect(() => {
    console.log('profile panel is rendered')
  }, [])

  const openModal = (e) => {
    setisOpen(!isOpen)
    setmodal(e.target.id)
  }
  const isEmpty = () => {
    let placeholder
    if (name === 'Name') placeholder = 'What would you like to be called ?'
    if (name === 'About you')
      placeholder = 'Write something about youself here.'
    return content !== '' ? content : placeholder
  }
  return (
    <div>
      <h5 className="text-xs">{name}</h5>
      <div className="mt-1.5 pb-1.5 border-b-0.5px border-gray-veryLight">
        <button
          id={name}
          onClick={openModal}
          type="button"
          className="flex justify-between w-full text-left"
        >
          <h4
            id={name}
            className={`flex font-semibold text-sm pr-2.5 ${
              content === '' ? 'text-gray-veryLight' : 'text-black'
            }`}
          >
            {Array.isArray(content)
              ? content.map((item) => item.firstname).join(', ')
              : isEmpty()}
          </h4>
          <img id={name} src={Arrow} alt={Arrow} />
        </button>
      </div>
      {modal !== '' && (
        <CurrentProfileModal
          modal={modal}
          isOpen={isOpen}
          setisOpen={setisOpen}
          content={content}
          setuser={setuser}
          callApi={callApi}
        />
      )}
    </div>
  )
}

export default memo(ProfilePanel)
